
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";

import ConfirmAction from "../../global/confirm-action";
import Icon from '@material-ui/core/Icon';
import SelectList from "../../global/form/select";
import PortfolioUnassignedUsers from "./portfolio_unassigned_users";
import PortfolioUsers from "./portfolio_users";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import PortfolioManageAccess from "./manage_access";
import PortfolioManageUsers from "./manage_users";
import GoogleAddress from "../../global/google-address";

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolioList: [],
            selectedPortfolioType: null,
            selectedPortfolioId: null,
            isOpenPortfolioAccess: false,
            isOpenPortfolioUsers: false,
            isOpenCreatePortfolio: false,
            isShowAssignedPartners: true,
            specialAccessUserIds: ["h5YDKq6hcu", "SVPBChhOn8", "8pLDzq2gDn", "WYqvJ2v7Oy", "JAFkXTGjnY"]
        };
    }


    componentDidMount(){
        this.selectPortfolioType(1);
    }

    fetchPortfolio = async(portfolioType) => {
        // store.dispatch(setLoader(true))
        try{
            var body = { portfolio_type: portfolioType };
            let apiData = await ApiEndpoints.Portfolio.fetchAccessiblePortfolio(body);
            let portfolioList = apiData.data.data;
            this.setState({ portfolioList });
            if(portfolioList.length === 1) this.selectPortfolio(portfolioList[0].portfolio_id);

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        // store.dispatch(setLoader(false))
    }

    
    selectPortfolioType = async (type) => {
        await this.setState({ selectedPortfolioType: type, selectedPortfolioId: null});
        this.fetchPortfolio(type)
    }

    selectPortfolio = async (portfolioId) => {
        await this.setState({ selectedPortfolioId: null});
        await this.setState({ selectedPortfolioId: portfolioId});
    }


    onChangeAddPortfolio = (k, v) => {
        let { isOpenCreatePortfolio } = this.state;
        if(k === 'location') {
            if(v.city){
                isOpenCreatePortfolio[k] = v.city;
                isOpenCreatePortfolio.location_type = "0";
            }
            else {
                isOpenCreatePortfolio[k] = v.state;
                isOpenCreatePortfolio.location_type = "1";
            }
            isOpenCreatePortfolio.portfolio_name = `${isOpenCreatePortfolio.portfolio_name || ""} - (${v.state}${v.city ? " - "+v.city : ""})`;
        }
        else isOpenCreatePortfolio[k] = v;

        this.setState({ isOpenCreatePortfolio })
    }

    onSubmitCreatePortfolio = async (e) => {
        let { isOpenCreatePortfolio, selectedPortfolioType } = this.state;
            e.preventDefault();
            try{
                if(!isOpenCreatePortfolio.location) throw new Error("Please select location");
                let portfolioData = {
                    portfolio_name: isOpenCreatePortfolio.portfolio_name,
                    portfolio_type: selectedPortfolioType,
                    location_type: isOpenCreatePortfolio.location_type,
                    location: isOpenCreatePortfolio.location
                };
                store.dispatch(setLoader(true));
                if(isOpenCreatePortfolio.portfolio_id) {
                    portfolioData.portfolio_id = isOpenCreatePortfolio.portfolio_id;
                    await ApiEndpoints.Portfolio.editPortfolio(portfolioData);
                }
                else await ApiEndpoints.Portfolio.createPortfolio(portfolioData);
                await this.setState({isOpenCreatePortfolio:false})
                await this.fetchPortfolio(selectedPortfolioType);
            }
            catch(err) {
                store.dispatch(setError({show: true, message: err.response ? err.response.data.message : err.toString()}))

            }
            store.dispatch(setLoader(false));
    }


  render() {
    let { portfolioList, selectedPortfolioType, selectedPortfolioId, specialAccessUserIds,isOpenCreatePortfolio, isShowAssignedPartners } = this.state;
    var user = store.getState().user;
    var userRole = user.UserRole
    var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;

    return (
    <>
       

        <Row className="align-items-center">
            <Col xs={4} md={2}>
                <SelectList search={true} placeholder="Select Type" value={selectedPortfolioType} onChange ={(e) => this.selectPortfolioType(e.target.value)} >
                    <option value={0}>Tide</option>
                    <option value={1}>Google Pay</option>
                    <option value={2}>B2C</option>
                </SelectList>
            </Col>
            <Col xs={8} md={4}>
                <SelectList search={true} placeholder="Select Team" value={selectedPortfolioId} onChange ={(e) => this.selectPortfolio(e.target.value)} >
                    {
                        [
                            ...portfolioList.map(portfolio => <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>{portfolio.portfolio_type === 1 ? "B2B" : "B2C"} - {portfolio.portfolio_name}</option>),
                            // <option disabled={true}><span className="border d-block w-100"></span></option>,
                            // <option disabled={!specialAccessUserIds.includes(user.user_id)} value="UNASSIGNED">Un-Assigned Partners</option>
                        ]
                    }
                    
                    
                </SelectList>
            </Col>
           
            {specialAccessUserIds.includes(user.user_id) &&
            <Col className="text-right">
                    {!!selectedPortfolioId && <Button outline className="px-1 mr-3 pb-1 pt-0" color="secondary" onClick={() => this.setState({isOpenCreatePortfolio: portfolioList.filter(o => o.portfolio_id === selectedPortfolioId)[0] })} type="button"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}}>edit</Icon></Button>}
                    <Button outline className="px-1 mr-3 pb-1 pt-0" color="secondary" onClick={() => this.setState({isOpenCreatePortfolio: {} })} type="button"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}}>add</Icon></Button>
            </Col>
            }
        </Row>

        {!!selectedPortfolioId && 
        <Row className={"my-2 align-items-end border-top border-bottom py-3"}>
            <Col md="auto" className="text-center">
                <ButtonGroup size="small" color="secondary">
                    <Button style={{fontSize: 10, borderColor: "#F32013", backgroundColor: !isShowAssignedPartners ? "#F32013" : "#FFF", color: !isShowAssignedPartners ? "#FFF" : "#F32013"}} onClick={() => this.setState({isShowAssignedPartners: false})}>Un-Assigned Partners</Button>
                    <Button style={{fontSize: 10, borderColor: "#F32013", backgroundColor: isShowAssignedPartners ? "#F32013" : "#FFF", color: isShowAssignedPartners ? "#FFF" : "#F32013", }} onClick={() => this.setState({isShowAssignedPartners: true})}>Assigned Partners</Button>
                </ButtonGroup>
            </Col>   
            {!!isAllowManagerActions && 
            <Col className="text-right">
                {specialAccessUserIds.includes(user.user_id) && <Button size="sm" className="buttonred ml-2" onClick={(e) => this.setState({ isOpenPortfolioUsers: true })}>Manage Partners</Button>}
                <Button size="sm" className="buttonred ml-3" onClick={(e) => this.setState({ isOpenPortfolioAccess: true })}>Manage Access</Button>
            </Col>
            }
        </Row>}


        {/* {selectedPortfolioType >= 0 && selectedPortfolioId === "UNASSIGNED" && <PortfolioUnassignedUsers portfolioType={selectedPortfolioType} portfolioList={portfolioList} /> }
        {selectedPortfolioType >= 0 && !!selectedPortfolioId && selectedPortfolioId !== "UNASSIGNED" && <PortfolioUsers portfolioType={selectedPortfolioType} portfolioId={selectedPortfolioId} specialAccessUserIds={this.state.specialAccessUserIds} /> } */}

        {selectedPortfolioType >= 0 && !!selectedPortfolioId && 
            <> 
                {
                    isShowAssignedPartners ? 
                        <PortfolioUsers portfolioType={selectedPortfolioType} portfolioId={selectedPortfolioId} specialAccessUserIds={this.state.specialAccessUserIds} />
                        :
                        <PortfolioUnassignedUsers portfolioId={selectedPortfolioId} portfolioType={selectedPortfolioType} portfolioList={portfolioList} />
                }
            </>
        }


        <Modal isOpen={!!this.state.isOpenPortfolioAccess} toggle={() => this.setState({isOpenPortfolioAccess: false})}>
            <ModalHeader toggle={() => this.setState({isOpenPortfolioAccess: null})}>Team Access</ModalHeader>
            <ModalBody>
                    {this.state.isOpenPortfolioAccess && <PortfolioManageAccess portfolioId={selectedPortfolioId} />}
            </ModalBody>
        </Modal>


        <Modal isOpen={!!this.state.isOpenPortfolioUsers} toggle={() => this.setState({isOpenPortfolioUsers: false})}>
            <ModalHeader toggle={() => this.setState({isOpenPortfolioUsers: null})}>Team Partners</ModalHeader>
            <ModalBody>
                    {this.state.isOpenPortfolioUsers && <PortfolioManageUsers portfolioId={selectedPortfolioId} onClose={() => { this.selectPortfolio(selectedPortfolioId); this.setState({isOpenPortfolioUsers: false}) } } />}
            </ModalBody>
        </Modal>

        
        <Modal centered isOpen={!!isOpenCreatePortfolio} toggle={() => this.setState({isOpenCreatePortfolio: false})}>
            <ModalHeader toggle={() => this.setState({isOpenCreatePortfolio: false})}>
                <span className="text-center text-dark mt-1" style={{fontSize: 16}} >Create New Team</span>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={(e)=> this.onSubmitCreatePortfolio(e)}>
                    <InputFeild required placeholder="Enter Team Name" rows={2} className=" w-100 mt-2 mb-3" style={{fontSize: 14}} value={isOpenCreatePortfolio.portfolio_name} onChange={(e) => this.onChangeAddPortfolio("portfolio_name", e.target.value) } />
                    <GoogleAddress defaultValue={isOpenCreatePortfolio.location} placeholder="Enter Team location" onPlaceChange={(loc) => this.onChangeAddPortfolio("location", loc)} />
                    <div className="mt-4 pt-2 text-right">
                        <Button className="buttonred mr-2" type="submit" >Save</Button>
                        <Button type="button" className="buttonred mr-2" onClick={() => this.setState({isOpenCreatePortfolio: false})}>Cancel</Button>
                    </div>
                </form>                      
            </ModalBody>
        </Modal>
       

      
        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />



    </>)
  }
}


export default Portfolio;
