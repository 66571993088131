import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import ProfileBasicDetails from "./basic_details";
import UserDocuments from "./user_documents";
import UserProjects from "./user_projects";
import UserBankDetails from "./user_bank_details";
import UserReferrals from "./fetch_referrals";
import UserWalletDetails from "./user_wallet_details";
import UserPayouts from "./user_payouts";
import UserCallDetails from "./call_details";
import { TrackEvent } from "../../../actions/ga"
import UserWhatsappDetails from "./whatsapp_history";
import ServicePurchase from "../reports/service_purchase";
import UserTickets from "./user_tickets";
import UserDetailsMyTeam from "./user_details_myteam";
import UserPortfolio from "./user_portfolio";


class PartnerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {details:{}},
            selectedProfileTab : 0,
            projects: [],
            isShowProjectDetails: false,
            transitionData: []
        }
    }

    componentDidMount(){
        TrackEvent("PARTNER_PROFILE", "PARTNER_PROFILE_DATA_VIEW", "PARTNER_PROFILE_DATA");
        this.profileDetail();

    }

    profileDetail = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }

            let profileResp = await ApiEndpoints.UserProfile.fetchProfile(body)
            let profileDetail = profileResp.data.data;
            if(profileDetail.details.profile_picture_key){
                let profileFileResp = await ApiEndpoints.Files.getFileID({file_id : profileDetail.details.profile_picture_key});
                profileDetail.details.profile_picture_url = profileFileResp.data.data[0] ? profileFileResp.data.data[0].url : null;
            }
            this.setState({ userInfo: profileDetail })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

     

    
    switchTab = (value) => {
        this.setState({ selectedProfileTab: value, isShowProjectDetails: false })
    }


     
    render() {
        let { selectedProfileTab, userInfo } = this.state;
        return (
            <>

                <Modal size="lg" style={{maxWidth: 600}} isOpen={true} toggle={()=> this.props.toggle()} >
                    <ModalHeader toggle={()=> this.props.toggle()} ><h5>Partner Details</h5></ModalHeader>
                    <ModalBody className="pt-0">
                        <Row>
                            <Col xs={3} className="bg-light">
                                {
                                    MENU.map((item, i) => 
                                        <div className="my-4">
                                            <h6 style={{cursor : "pointer", fontSize: 14}} className={"pb-1 "+(selectedProfileTab === i && "border-bottom border-danger")} onClick={() => this.switchTab(i)}>{item}</h6>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col xs={9}>
                                { selectedProfileTab === 0 && <ProfileBasicDetails user_id={this.props.user_id} profileDetail={userInfo}  /> }
                                { selectedProfileTab === 1 && <UserDocuments user_id={this.props.user_id} applicationData={this.props.application_data}  /> }
                                { selectedProfileTab === 2 && <UserBankDetails user_id={this.props.user_id} /> }
                                { selectedProfileTab === 3 && <UserReferrals user_id={this.props.user_id} /> }
                                { selectedProfileTab === 4 && <UserWalletDetails user_id={this.props.user_id} /> }
                                { selectedProfileTab === 5 && <UserPayouts user_id={this.props.user_id} /> }
                                { selectedProfileTab === 6 && <UserProjects user_id={this.props.user_id} applicationData={this.props.application_data}  /> }
                                { selectedProfileTab === 7 && <UserDetailsMyTeam user_id={this.props.user_id} /> }

                                { selectedProfileTab === 8 && <UserCallDetails user_id={this.props.user_id}  userInfo={userInfo} /> }
                                {/* { selectedProfileTab === 8 && <UserWhatsappDetails user_id={this.props.user_id}  userInfo={userInfo} /> } */}
                                {/* { selectedProfileTab === 9 && <ServicePurchase.ServicePurchase user_id={this.props.user_id}  /> } */}
                                { selectedProfileTab === 9 && <UserTickets user_id={this.props.user_id}  /> }
                                { selectedProfileTab === 10 && <UserPortfolio user_id={this.props.user_id}  /> }
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
            
            
        );
    }
}
export default PartnerDetail;


const MENU = ["Profile Details", "Documents", "Bank Details", "Referrals", "Earnings", "Payouts", "Projects", "My Team", "Call Data", "Tickets", "Portfolio Mapping"];
