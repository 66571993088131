
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import { Badge, Checkbox, Icon, Switch, TablePagination } from "@material-ui/core";
import moment from "moment";
import PartnerDetail from "../user_profile";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import WhatsappChat from "../../global/whatsapp_chat";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import UserCard from "../user_profile/user_card";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import DatePicker from "react-datepicker";

class PortfolioUnassignedUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            startDate: moment().subtract(3, "months").toDate(),
            endDate: null,
            selectedUserIds: [],
            selectedPortfolioId: this.props.portfolioId,
            isOpenPortfolioMapping: false,
            currentUserPage : 0,
            rowsPerPage : 20,
            searchText: "",
            isOpenProfileModel: false,
            callDetails: null,
            whatsappChat: false,
            isOpenNotificationModal: false,
            callData: [],
            selectedState: "",
            isShowOnlyPendingAction : false,


        };
    }


    componentDidMount(){
        this.fetchToBeMappedUsers();
    }


    componentDidUpdate(prevProps){
        if(prevProps.portfolioType !== this.props.portfolioType) this.fetchToBeMappedUsers(this.props.portfolioType);
    }

    fetchToBeMappedUsers = async() => {
        store.dispatch(setLoader(true));
        let { startDate, endDate } = this.state;
        try{
            var body = { portfolio_type: parseInt(this.props.portfolioType) || 1, portfolio_id: this.props.portfolioId }; //default b2b
            if(this.state.isShowOnlyPendingAction) body.onlyPendingAction = true;
            if(startDate) body.start_date = moment(startDate).utcOffset("+00:00").toDate();
            if(endDate) body.end_date = moment(endDate).utcOffset("+00:00").toDate();

            let apiData = await ApiEndpoints.Portfolio.fetchToBeMappedUsers(body);
            let userList = apiData.data.data;
            this.setState({ userList, currentUserPage: 0 })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onSelectUser = (e) => {
        let {selectedUserIds} = this.state;
        if(e.target.checked) selectedUserIds.push(e.target.value);
        else {
            selectedUserIds.splice(selectedUserIds.indexOf(e.target.value), 1)
        }
        this.setState({selectedUserIds})
    }


    mapPartnerToPortfolio = async() => {
        store.dispatch(setLoader(true));
        let { selectedUserIds, selectedPortfolioId } = this.state;
        try{
            var body = { portfolio_id: selectedPortfolioId, user_id: selectedUserIds };
            await ApiEndpoints.Portfolio.mapPartnerToPortfolio(body);
            this.setState({ selectedPortfolioId: null, selectedUserIds: [], isOpenPortfolioMapping: false })
            this.fetchToBeMappedUsers(this.props.portfolioType);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : this.state.isOpenNotificationModal,
            notification_type : e.target.notification_type.value,
            origin_type : "GENERAL",
            sms : false
        } 
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }
  

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "PORTFOLIO",
                entity_id: this.state.selectedPortfolioId,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }


    showCallData = async (user) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                // status: "completed",
                user_id: user.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangePendingActionToggle = async (isOn) => {
        await this.setState({isShowOnlyPendingAction: isOn});
        this.fetchToBeMappedUsers()
    }

  render() {
    let { startDate, endDate, userList, searchText, selectedUserIds, isOpenPortfolioMapping, selectedPortfolioId, selectedState } = this.state;
    let { portfolioList= [] } = this.props;
    if(searchText) {
        searchText = searchText.toLowerCase().replace("pmw", "");
        userList = userList.filter(obj =>  (obj.first_name || "").toLowerCase().includes(searchText) ||  ("" + obj.id).includes(searchText));
    }

    var userRole = store.getState().user.UserRole
    var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;


    return (<>

        <Row className={"my-2 pt-2 border-top align-items-end"}>
            <Col xs={12} md={4}>
                <strong className="m-0 mr-2">Unassigned Partners</strong>
                {!!selectedUserIds.length && <Button size="sm" className="buttonred ml-3" onClick={(e) => this.setState({ isOpenPortfolioMapping: true })}>Map to Team</Button>}

                 {/* <Switch size="small" color="secondary" checked={this.state.isShowOnlyPendingAction} onChange={(e) => this.onChangePendingActionToggle(e.target.checked)} />
                 <strong className="m-0 ml-2">Pending Actions</strong> */}

            </Col>  
            <Col className="d-flex">
                <Row>
                    <Col>
                        <DatePicker
                            placeholderText="Start Date"
                            selected={startDate || null}
                            onChange={async (date) => {await this.setState({startDate: date}); this.fetchToBeMappedUsers();}}
                            // selectsStart
                            dateFormat='dd-MMM-yyyy'
                            isClearable
                            startDate={startDate || null}
                            endDate={endDate || null}
                            id="portfolio_start_date"
                        />
                    </Col>
                    <Col>
                        <DatePicker
                            className=""
                            placeholderText="End Date"
                            selected={endDate || null}
                            onChange={async (date) => { await this.setState({endDate: date}); this.fetchToBeMappedUsers();}}
                            // selectsEnd
                            dateFormat='dd-MMM-yyyy'
                            isClearable
                            startDate={startDate || null}
                            endDate={endDate || null}
                            id="portfolio_end_date"
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="justify-self-end">
                <form onSubmit={async (e) => {e.preventDefault(); await this.setState({ searchText: e.target.searchText.value, currentUserPage: 0});}}>
                    <InputFeild name="searchText" icon="search" onClickIcon={this.applyFilter} placeholder="Search by Name/PMW-Id" />
                </form>
            </Col>
           
        </Row>
        {
        userList.length ? 
        <>
        {
        userList
        .slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage)
        .map((mappedUser, i) =>
             <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                <Col style={{maxWidth: 25}}>
                    {isAllowManagerActions && <Checkbox color="secondary" size="small" value={mappedUser.user_id} checked={selectedUserIds.indexOf(mappedUser.user_id) !== -1} onChange={this.onSelectUser} />}
                </Col>
                <Col>
                    <Row noGutters className="align-items-center">
                        <Col className="align-items-center">
                            <UserCard data={mappedUser} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} xs={12} className="text-center my-1">
                    <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(mappedUser)}>{mappedUser.connected_call_counts || ""}</span> 
                    <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(mappedUser), isConfirmMessage : "Are you sure to connect with "+ mappedUser.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: mappedUser})}><img src={WhatsappIcon} alt="" style={{fontSize:16, cursor: "pointer"} }/></Button></Badge> */}
                    <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: mappedUser.user_id})}>notifications_none</Icon></Button>
                    {/* <Button outline className="px-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.showApplicationComment(applicant)}>comment</Icon></Button> */}
                </Col>
                <Col md={2} xs={12} className="text-center">
                    {
                         !!mappedUser.isPendingWhatsapp &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Whatsapp - {moment(mappedUser.isPendingWhatsapp.created_on).fromNow()} </span>
                    }
                    {
                       !!mappedUser.isMissedCall &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(mappedUser.isMissedCall.created_on).fromNow()} </span>
                    }
                </Col>
                <Col md={3} xs={12} className="text-center">
                    {
                        !!mappedUser.last_call && <span className="small" style={{fontSize: 10}}><span role="img" aria-label="icon" style={{fontSize: 10}}>&#128222;</span>{mappedUser.last_call.status}-{moment(mappedUser.last_call.call_scheduled_on || mappedUser.last_call.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")} {!!mappedUser.last_call.comment && `(${mappedUser.last_call.comment})`}</span>
                    }
                </Col>

                <Col md={2} xs={12} className="text-right"> 
                    <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">Downloaded On: <br />{moment(mappedUser.created_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                </Col>
            </Row>
            )}
            <TablePagination rowsPerPageOptions={[20, 40, 60, 100, 200]} component="div" count={userList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
               onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
               onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
            />
            </>
            : 
            <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
            
        }


        <Modal isOpen={isOpenPortfolioMapping} toggle={() => this.setState({isOpenPortfolioMapping: false})}>
            
            <ModalHeader toggle={() => this.setState({isOpenPortfolioMapping: false})}> <h5>Map partners to Team</h5></ModalHeader>
                <ModalBody>
                    <SelectList search={true} placeholder="Select Portfolio" value={selectedPortfolioId} onChange ={(e) => this.setState({selectedPortfolioId: e.target.value})} >
                        { portfolioList.map(portfolio => <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>{portfolio.portfolio_type === 1 ? "B2B" : "B2C"} - {portfolio.portfolio_name}</option>)}
                    </SelectList>
                </ModalBody>
                <ModalFooter style={{height: "45px"}}>
                <Button  size={"sm"} color="primary" onClick={() => this.mapPartnerToPortfolio()}>Map Partners</Button>{' '}
                <Button type="button" size={"sm"} onClick={() => this.setState({isOpenPortfolioMapping: false})}>Cancel</Button>
            </ModalFooter>
           
        </Modal>

        <Modal isOpen={this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
            <form onSubmit={this.handleNotificationSubmit}>
                <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                    <ModalBody>
                        <label className="radio-inline">General Notification</label>
                        <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                        <InputFeild required placeholder="Title" name="title" />
                        <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                        {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                    </ModalBody>
                    <ModalFooter style={{height: "45px"}}>
                    <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                    <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>

        <Modal size={"lg"} centered isOpen={!!this.state.callData.length} toggle={()=> {this.setState({callData : []})}} >
                    <ModalHeader toggle={()=> {this.setState({callData : []})}}>Call Details</ModalHeader>
                    <ModalBody className="p-2">
                    <Table bordered className="table-sm mt-2 mb-0 text-center" style={{fontSize: 14}}>
                                <thead>
                                    <tr>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.callData.map((call, i) =>
                                    <tr>
                                      <td>{(call.userDetail || {}).first_name}</td>
                                      <td>{call.direction === 1 ? "In" : "Out"}</td>
                                      <td>
                                        {call.call_recording_url ?
                                        <audio preload={true} style={{maxWidth: 185}} controls><source src={call.call_recording_url} type="audio/ogg"  /></audio>
                                        :
                                        <span>{call.status}</span>
                                        }
                                      </td> 
                                      <td><span>{call.comment}</span></td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        {/* <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em> */}
                                      </td>
                                    </tr>
                                  ) 
                                  }
                                </tbody>
                            </Table>  
                    </ModalBody>
            </Modal>







        {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}
        {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}



    </>)
  }
}


export default PortfolioUnassignedUsers;
