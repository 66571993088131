import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, Popover, PopoverBody, PopoverHeader } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";
import moment from "moment";
import PartnerMapDetail from "../dashboard/partner_map_detail";
import InputFeild from "../../global/form/input";
import JobInformation from "../project/job_information";
import { copyCodeToClipboard, groupArrayOnKey } from "../../../actions";

class UserProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 1,
            projects: [],
            availableJobs: [],
            selectedJob: null,
            isOpenPopover: false,
            isShowJobDetails: false,
            searchJobText: ""
        }
    }

    componentDidMount(){
       this.fetchProjectDetails();
    }

   
    selectTab = ( tab ) => {
        if(tab === 1){
            this.fetchProjectDetails();
        } else {
            this.fetchAvailableJobs();
        }
        this.setState({ selectedTab: tab })
    }

    fetchProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, archive_status: [0 , 1, 2] }
            let projectsResp = await ApiEndpoints.UserProfile.fetchUserActiveProjects(body)
            let projects = projectsResp.data.data.sort((a, b) => new Date(b.last_task_date) - new Date(a.last_task_date))
            this.setState({ projects })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchAvailableJobs = async () => {
        try{
            var body = { user_id : this.props.user_id }

            let resp = await ApiEndpoints.UserProfile.userVisibleProject(body)
            let availableJobs = resp.data.data || [];
            availableJobs = groupArrayOnKey(availableJobs, "job_category_id");
            this.setState({ availableJobs })

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

     


      showTaskData = async (job) => {
        store.dispatch(setLoader(true))
        try{
            var body = { job_id: job.job_id };
            let resp = await ApiEndpoints.JobTask.fetch(body);
            let taskList = resp.data.data;
            this.setState({ selectedJob: {...job, taskList} })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }
     
      downloadFileByID = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileBody = {file_id : fileId}
            let urlResp = await ApiEndpoints.Files.getFileID(fileBody);
            let fileUrl = urlResp.data.data[0] ? urlResp.data.data[0].url : null;

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = true;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    togglePopover = (job) => {
        this.setState({
            isOpenPopover: this.state.isOpenPopover ? false : {...job}
        });
    }

    onChangeArchiveStatus = (key,value) => {
        let { isOpenPopover } = this.state;
        isOpenPopover.application = isOpenPopover.application || {};
        isOpenPopover.application[key] = value;
        this.setState({ isOpenPopover });
    }

    archiveStatusSave = async (job) => {
        
        store.dispatch(setLoader(true))
        let { availableJobs } = this.state;
        try{
            if(job.application && job.application.application_id){
                let body ={application_id : job.application.application_id , archive_status : job.application.archive_status };
                if(job.application.archive_message) body.archive_message = job.application.archive_message;
                await ApiEndpoints.Applicants.update(body);
            } else {
                var body ={job_id : job.job_id, user_id: this.props.user_id, job_location_id: job.JobLocations[0].job_location_id , archive_status : job.application.archive_status };
                if(job.application.archive_message) body.archive_message = job.application.archive_message;
                await ApiEndpoints.Applicants.insertApplication(body)
            }
            Object.keys(availableJobs).map(jobCategoryId => {
                availableJobs[jobCategoryId] = availableJobs[jobCategoryId].map(obj => {
                    if(obj.job_id === job.job_id) obj = job;
                    return obj;
                });
            })
            
            this.setState({availableJobs, isOpenPopover: false})
          }
          catch(e){
              store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
  
      }

      getJobShareLink = (job) => {
        let link = `https://link.abbecho.com/fr/click/${Buffer.from(String(job.job_id)).toString("base64")}/u/${Buffer.from(this.props.user_id).toString("base64")}`;
        copyCodeToClipboard(link);
        store.dispatch(setSuccess({show: true, message: "Link Copied!"}))
      }

    render() {
        let {selectedTab, projects, selectedJob, availableJobs, isShowJobDetails, searchJobText} = this.state;
        let filteredAvailableJobs = {...availableJobs};
        if(searchJobText){
            if(selectedTab === 1) projects = projects.filter(job => ((job.employer_name + " - " + job.title) || "").toLowerCase().includes(searchJobText.toLowerCase()))
            else {
                Object.keys(availableJobs).map(jobCategoryId => {
                    filteredAvailableJobs[jobCategoryId] = filteredAvailableJobs[jobCategoryId].filter(job => ((job.Employer.employer_name + " - " + job.title) || "").toLowerCase().includes(searchJobText.toLowerCase()))
                });
            }
        }
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
        
        return (
        <>
            <div className="px-2 py-3">
                <Row>
                    <Col>
                        <span className={`px-3 py-1 bg-light border border${selectedTab === 1 ? "-danger" : "-light"} small clickable`} style={{borderRadius: 12}} onClick={() => this.selectTab(1)}>Active Projects</span>
                        <span className={`px-3 py-1 bg-light border border${selectedTab === 2 ? "-danger" : "-light"} small clickable ml-3`} style={{borderRadius: 12}} onClick={() => this.selectTab(2)}>All Projects</span>
                    </Col>
                    <Col xs={4}>
                        <InputFeild placeholder="Search" onChange={(e) => this.setState({searchJobText : e.target.value})} />
                    </Col>
                </Row>
            </div>
            {selectedTab === 1 ?
            <>
                {!selectedJob ?
                    projects.map(job => 
                    <div>
                        <Row className="align-items-center bg-light shadow-sm mt-3 mx-1 border py-2" style={{fontSize: 14, borderRadius: 10}}>
                            <Col xs={2} className="text-center clickable" onClick={() => this.setState({ isShowJobDetails: job })}>
                                <img src={job.logo_key} style={{width: "100%", aspectRatio: "1", borderRadius: "50%"}} alt="project_logo" />
                            </Col>
                            <Col xs={7}>
                                <strong className="clickable" onClick={() => this.setState({ isShowJobDetails: job })}>{job.employer_name} - { job.job_title }</strong>
                                {job.job_archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.job_archive_status]} /> }
                                {job.first_task_date && <span className="d-block small">First Task On: {moment(job.first_task_date).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>}
                                {job.last_task_date && <span className="d-block small">Last Task On: {moment(job.last_task_date).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>}
                                <>
                                    {job.extra_details_status === 1 && <span  style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Initiated</span>}
                                    {job.extra_details_status === 2 && <span  style={{fontSize: 10, color: "orange", whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px", color: "orange"}}>check_circle_outline</Icon>ID Requested</span>}
                                    {job.extra_details_status === 3 && <span  style={{fontSize: 10, color: "green", whiteSpace: "nowrap"}} ><Icon style={{fontSize: 10, lineHeight: "14px"}}>task_alt</Icon>ID Added</span>}
                                    {job.extra_details_status === 4 && <span  style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Expired</span>}
                                </>
                            </Col>
                            <Col xs={3} className="text-right">
                                <strong className="clickable d-block" onClick={() => this.showTaskData(job)}><u>{job.exact_task_done}</u></strong>
                                <span className="small">All Tasks</span>
                            </Col>
                            <Col xs={12} className="border-top mt-2 pt-2">
                                <Row>
                                    <Col>
                                        <span>Pending: </span><span>{(job.statusCount || {})['0'] || 0}</span>
                                        <span className="ml-3">Rejected: </span><span>{(job.statusCount || {})['2'] || 0}</span>
                                        <span className="ml-3">Verified: </span><span>{(job.statusCount || {})['1'] || 0}</span>
                                    </Col>
                                    <Col xs="auto">
                                        <span className="small font-weight-bold float-left"> {CONSTANTS.APPLICATION_STATUS_TEXT[job.status]}</span>
                                        {/* <div id={"project_"+job.job_id} onClick={() => this.togglePopover(job)} className="ml-2 small float-right"> */}
                                        <div id={"project_"+job.job_id} className="ml-2 small float-right" title={job.application_archive_message}>
                                            {/* {job.application_archive_status === 0 && <span style={{color: "green", cursor: "pointer"}}>● On</span>} */}
                                            {job.application_archive_status === 1 && <span style={{color: "orange", cursor: "pointer"}}>● Hold</span>}
                                            {job.application_archive_status === 2 && <span style={{color: "red", cursor: "pointer"}}>● Off</span>}                                    
                                        </div>
                                    </Col>
                                </Row>
                            
                            </Col>
                        </Row>
                    </div>
                    )
                :
                <div>
                    <span className="m-2 d-block pb-2 border-bottom">
                        <Icon fontSize="small" style={{cursor: "pointer"}} className="mr-3 align-middle" onClick={() => this.setState({selectedJob: null})}>arrow_back</Icon>
                        <strong className="clickable" onClick={() => this.setState({ isShowJobDetails: selectedJob })}>{selectedJob.employer_name} - { selectedJob.job_title }</strong>
                    </span>
                    <PartnerMapDetail user={{user_id: this.props.user_id}} selectedJob={selectedJob} taskList={selectedJob.taskList} />
                </div>
                }
               
            </>
            : 
            <div>
                {Object.keys(filteredAvailableJobs).map(jobCategoryId => <div>
                    {!!filteredAvailableJobs[jobCategoryId][0] && <strong className="mt-3">{filteredAvailableJobs[jobCategoryId][0].JobCategory ? filteredAvailableJobs[jobCategoryId][0].JobCategory.title : "Other"}</strong>}
                    
                    { filteredAvailableJobs[jobCategoryId].map((job , i) => {
                        return <div key={i} className="small mb-2 py-2 px-3 border bg-light shadow-sm" style={{borderRadius: 8}}> 
                            <Row noGutters className="align-items-center">
                                <Col xs="auto">
                                    <img src={job.Employer.logo_key} style={{maxWidth: 30, width: 30, aspectRatio: "1", borderRadius: "50%"}} alt="project_logo" />
                                </Col>
                                <Col xs={7} className="clickable"  onClick={() => this.setState({ isShowJobDetails: job })}>
                                    <span className="ml-2">{job.Employer.employer_name + " - " + job.title}</span>
                                    {job.archive_status !== 0 && <span style={{fontSize: 7}} className="ml-3 bg-light shadow-sm px-2 border font-weight-bold py-1">{CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} </span> }
                                </Col>
                                <Col className="text-right">
                                        {job.action_status > 10 && isAdmin && !job.archive_status && <Button size="sm" color="danger" style={{fontSize: 10}} onClick={() => this.getJobShareLink(job)}>Link</Button>}

                                        <div id={"user_project_"+job.job_id} className="ml-2 small d-inline-block" title={job.application && job.application.archive_message} onClick={() => this.togglePopover(job)}>
                                            {(!job.application || !job.application.archive_status) && <span style={{color: "green", cursor: "pointer"}}>● On</span>}
                                            {job.application && job.application.archive_status === 1 && <span style={{color: "orange", cursor: "pointer"}}>● Hold</span>}
                                            {job.application && job.application.archive_status === 2 && <span style={{color: "red", cursor: "pointer"}}>● Off</span>}                                    
                                        </div>
                                        {this.state.isOpenPopover.job_id === job.job_id && <Popover target={"user_project_"+job.job_id} placement="bottom" isOpen={this.state.isOpenPopover.job_id === job.job_id} toggle={() => this.setState({isOpenPopover : false })}>
                                            <PopoverHeader>Status <Icon className="float-right" style={{cursor: "pointer", fontWeight: 800, fontSize: 18}} onClick={() => this.setState({isOpenPopover : false })}>close</Icon></PopoverHeader>
                                            <PopoverBody>
                                                <Row className="justify-content-center my-3" style={{width: 300}}>
                                                    <Col xs={6}>
                                                        <div className="switch-toggle" style={{fontSize: 12}}>
                                                        {Object.keys(CONSTANTS.ARCHIVE_STATUS).map((key, i) => 
                                                            <div key={i} className={(this.state.isOpenPopover.application && this.state.isOpenPopover.application.archive_status) === CONSTANTS.ARCHIVE_STATUS[key] ? "active" : ""} onClick={() => this.onChangeArchiveStatus("archive_status", CONSTANTS.ARCHIVE_STATUS[key]) }>{key}</div>
                                                        )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.state.isOpenPopover.application && this.state.isOpenPopover.application.archive_status === 1 && <InputFeild className="my-2 p-0" style={{fontSize: 12}} placeholder={"Enter the Hold message"} value={this.state.isOpenPopover.application.archive_message || ""} onChange={(e) => this.onChangeArchiveStatus("archive_message", e.target.value) } />}
                                                <Button id={"archive_status_submit"+job.job_id} size="sm" block className="buttonred mt-2" onClick={() => this.archiveStatusSave(this.state.isOpenPopover)}>Save</Button>
                                            </PopoverBody>
                                        </Popover>  }     
                                </Col>
                            </Row>   
                                         
                        </div>
                    })}
                </div>
                )}
            </div>
        }
        <Modal centered size="lg" isOpen={!!isShowJobDetails} toggle={() => this.setState({ isShowJobDetails: null })}>
            <ModalBody  toggle={() => this.setState({ isShowJobDetails: null })}>
                {!!isShowJobDetails && <JobInformation job={isShowJobDetails} isOnlyView={true} />}
            </ModalBody>
        </Modal>
        </>            
        );
    }
}
export default UserProjects;
