
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Col, Row } from "reactstrap";
import { Drawer, Button, Switch } from "@material-ui/core";
import InputField from "../form/input";
import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import TicketDetail from "./ticket"
import PartnerDetail from "../../views/user_profile";
import {io} from "socket.io-client"
import CONSTANTS from "../../../constants";
import TicketCreate from "./ticket_create";
import moment from "moment";
import DatePicker from "react-datepicker";
import SelectList from "../form/select";
import { groupArrayOnKey } from "../../../actions";

var socket = null;

const TICKET_TABS = [
  { label: "Your Pending", status: "awaiting_reply", filters: { status: 20 }, countKey: 20 },
  { label: "Partner's Pending", status: "awaiting_response", filters: { status: 10 }, countKey: 10 },
  { label: "Open Issues", status: "open", filters: { status: 0 }, countKey: 0 },
  { label: "Resolved", status: "closed", filters: { status: 30 }, countKey: 30 },
  // { label: "Pinned", status: "pinned", filters: { is_bookmarked: 1 }, countKey: "is_bookmarked" }
]


class SupportTickets extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isAdminView: false,
        tabCounts: {},
        activeTab : "awaiting_reply" ,
        activeTicket: false,
        tickets: [],
        ticketSearchText: "",
        isOpenUserModel: null,
        isCreateTicket: false,
        ticketAgents: [],
        ticketTypeFilterData: {},
        ticketFilterJobs : [],
        isFilters: {},

      }
    }

    componentDidMount(){
      this.fetchTicketCounts();
      this.fetchTickets();


      const SERVER = process.env.REACT_APP_BASE_URL;
      socket = io (SERVER, { transports: ['websocket']});
      socket.emit("join_ticket_support");
      socket.on('TICKET_CREATED', (resp) => {
        this.listenCreateTicket(resp);
      });
      socket.on('TICKET_UPDATED', (resp) => {
        this.listenUpdateTicket(resp);
      });
    }

    onClose = () => {
      var user = store.getState().user;
      // var userRole = user.UserRole;
      // let isAdmin = !!userRole.filter(role => role.role_id === 1).length;
      let { tabCounts } = this.state;
      if(user.user_id === "yUnEHzXJuj" || (tabCounts[20] || 0) === 0)  this.props.onClose && this.props.onClose();
      // if(isAdmin || (tabCounts[20] || 0) === 0)  this.props.onClose && this.props.onClose();
    }

    fetchTicketCounts = async () => {
      try{
        let { isAdminView } = this.state;
        let body = {  };
        if(isAdminView) body.includeAll = true;
        let apiData = await ApiEndpoints.Support.fetchTicketCounts(body);
        this.setState({tabCounts: apiData.data.data});
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
    }

    fetchTickets = async () => {
      store.dispatch(setLoader(true));
      let { isAdminView, activeTab, isFilters } = this.state;
      try{
        let activeTabConfig = TICKET_TABS.filter(o => o.status === activeTab)[0];
        let body = { ...activeTabConfig.filters };
        if(isAdminView) body.includeAll = true;
        

        let apiData = await ApiEndpoints.Support.fetchTickets(body);
        let tickets = apiData.data.data;



        this.prepareFiltersData(tickets);
        tickets = tickets.map(ticket => {
          ticket.ticket_type = ticket.ticket_type === null ? null : String(ticket.ticket_type);
          return ticket;
        })
        this.setState({ tickets });
      }
      catch(e){
        console.log(activeTab, e);
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    searchTicketByID = async(e) => {
      e.preventDefault();
      e.persist();
      let ticketId = e.target.search_ticket.value;
      if(!ticketId) {
        return await this.selectTab(TICKET_TABS[0]);
      }
      store.dispatch(setLoader(true));
      try{
        let body = {includeAll: true, ticket_id: ticketId };
        let apiData = await ApiEndpoints.Support.fetchTickets(body);        
        let tickets = apiData.data.data;
        this.setState({ activeTab: null, tickets })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    prepareFiltersData = (tickets) => {
      let jobList = store.getState().jobList;
      jobList = groupArrayOnKey(jobList, "job_id", true);
      let ticketTypeFilterData = {}
      let ticketFilterJobs = {};
      let ticketAgents = tickets.reduce((prev, curr) => {
        curr.ticket_type = curr.ticket_type === null ? 'NA' : curr.ticket_type;
        ticketTypeFilterData[curr.ticket_type] = ticketTypeFilterData[curr.ticket_type] ? ticketTypeFilterData[curr.ticket_type] + 1 : 1;
        if(curr.tag_type === "JOB" && curr.tag_id && jobList[curr.tag_id]) ticketFilterJobs[curr.tag_id] = ticketFilterJobs[curr.tag_id] ? {...ticketFilterJobs[curr.tag_id], ticketCount: ticketFilterJobs[curr.tag_id].ticketCount + 1} : {...jobList[curr.tag_id], ticketCount: 1};
        if(curr.Agent) prev[curr.agent_user_id] = prev[curr.agent_user_id] ? {...prev[curr.agent_user_id], ticketCount: prev[curr.agent_user_id].ticketCount+1} : {...curr.Agent, ticketCount: 1};
        return prev;
      }, {});
      this.setState({ ticketAgents: Object.values(ticketAgents), ticketTypeFilterData, ticketFilterJobs: Object.values(ticketFilterJobs) })

    }

    showAdminView = async (isAdmin) => {
      await this.setState({ isAdminView: !!isAdmin });
      this.fetchTicketCounts();
      this.fetchTickets();
    }

    refreshTickets = async () => {
      this.setState({isRefreshing: true})
      this.fetchTicketCounts();
      await this.fetchTickets();
      this.setState({isRefreshing: false})
    }

    selectTab = async (tab) => {
      await this.setState({ activeTab: tab.status, activeTicket: false });
      this.fetchTickets();
    }

    selectTicket = async (ticket) => {
      await this.setState({ activeTicket: false });
      this.setState({ activeTicket: ticket });
    }
    
    openCreateTicket = () => {
      this.setState({ isCreateTicket: !this.state.isCreateTicket, activeTicket: false });
    }
    
    markTicketResolved = async (ticketData) => {
      store.dispatch(setLoader(true));
      let { tickets, tabCounts, activeTab } = this.state;
      try{
        var body = { ticket_id: ticketData.ticket_id }
        await ApiEndpoints.Support.updateTicketResolved(body);

        tickets = tickets.filter(t => ticketData.ticket_id !== t.ticket_id);
        this.selectTicket(tickets[0] || false);
        tabCounts[30] = (tabCounts[30] || 0) + 1;
        let activeTabKey = TICKET_TABS.filter(o => o.status === activeTab)[0].countKey;
        tabCounts[activeTabKey] = tabCounts[activeTabKey] - 1;
        this.setState({ tickets, tabCounts });
        if(document.getElementById("ticket-container")) document.getElementById("ticket-container").scrollTop = 0;
      }
      catch(e){
        store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
      }
      store.dispatch(setLoader(false));
    }


    updateTicketData = (ticket, updateKey) => {
      let { tickets, activeTicket, tabCounts } = this.state;
      tickets = tickets.map(obj => obj.ticket_id === ticket.ticket_id ? ticket : obj);
      activeTicket = activeTicket.ticket_id === ticket.ticket_id ? ticket : activeTicket;
      if(updateKey === "is_bookmarked"){
        tabCounts["is_bookmarked"] = (tabCounts["is_bookmarked"] || 0) + (ticket.is_bookmarked ? 1 : -1);
      }
      this.setState({ tickets, activeTicket, tabCounts })
    }

    listenCreateTicket = (obj) => {
      let { tickets, tabCounts, activeTab } = this.state;
      let activeTabKey = TICKET_TABS.filter(o => o.status === activeTab)[0].countKey;
      let user = store.getState().user;

      if(this.state.isAdminView || obj.ticket.agent_user_id === user.user_id){
        tabCounts[obj.ticket.status] = (tabCounts[obj.ticket.status] || 0) + 1;
        if(activeTabKey === obj.ticket.status) tickets.push(obj.ticket);
      }else if (obj.ticket.status === 0) {
        tabCounts[0] = (tabCounts[0] || 0) + 1;
        if(activeTabKey === 0) tickets.push(obj.ticket);
      }
      this.setState({ tickets, tabCounts });
    }
  
    listenUpdateTicket = (obj) => {
      let { tickets, tabCounts, activeTab, activeTicket } = this.state;
      let activeTabKey = TICKET_TABS.filter(o => o.status === activeTab)[0].countKey;
      let user = store.getState().user;
      if(this.state.isAdminView) return null;
      if(obj.update === 'status' && obj.ticket.agent_user_id === user.user_id){
        if(!obj.ticket.History && obj.ticket.status === 20){
          tabCounts[20] = (tabCounts[20] || 0) + 1;
          tabCounts[10] = (tabCounts[10] || 0) - 1;
          if(activeTabKey === 10) tickets = tickets.filter(t => t.ticket_id !== obj.ticket.ticket_id);
          else if (activeTabKey === 20) tickets.unshift(obj.ticket);
        }
        if(!obj.ticket.History && obj.ticket.status === 10){
          tabCounts[20] = (tabCounts[10] || 0) + 1;
          tabCounts[10] = (tabCounts[20] || 0) - 1;
          if(activeTabKey === 20) tickets = tickets.filter(t => t.ticket_id !== obj.ticket.ticket_id);
          else if (activeTabKey === 10) tickets.unshift(obj.ticket);
        }
        if(obj.ticket.History){
          tabCounts[obj.ticket.status] = (tabCounts[obj.ticket.status] || 0) + 1;
          tabCounts[obj.ticket.History.field_past_value] = (tabCounts[obj.ticket.History.field_past_value] || 0) - 1;
          if(activeTabKey === obj.ticket.History.field_past_value) tickets = tickets.filter(t => t.ticket_id !== obj.ticket.ticket_id);
          else if (activeTabKey === obj.ticket.status) tickets.unshift(obj.ticket);
        }
      }
      if(obj.update === 'agent_user_id' && obj.ticket.History){
        if(obj.ticket.History.field_past_value === user.user_id && obj.ticket.History.field_value !== user.user_id){
          tabCounts[obj.ticket.status] = (tabCounts[obj.ticket.status] || 0) - 1;
          tickets = tickets.filter(t => t.ticket_id !== obj.ticket.ticket_id);
          if(activeTicket && activeTicket.ticket_id === obj.ticket.ticket_id) activeTicket = false;
        }
        if(obj.ticket.History.field_past_value !== user.user_id && obj.ticket.History.field_value === user.user_id){
          tabCounts[obj.ticket.status] = (tabCounts[obj.ticket.status] || 0) + 1;
          if(activeTabKey === obj.ticket.status) tickets.unshift(obj.ticket);
        }
      }
      this.setState({ tickets, tabCounts, activeTicket });
    }
  
    addTicketInData = (ticket, isSelectTicket) => {
      let { tickets, activeTicket, activeTab, tabCounts } = this.state;
      tickets.unshift(ticket);
      tabCounts[ticket.status] = (tabCounts[ticket.status] || 0) + 1;
      if(isSelectTicket && ticket.status < 40){
        let activeTabConfig = TICKET_TABS.filter(o => o.countKey === ticket.status)[0];
        activeTab = activeTabConfig.status;
        activeTicket = ticket;
      }
      this.setState({ tickets, activeTicket, activeTab, tabCounts });
    }

    onChangeFilter = async (key, value) => {
      let { isFilters } = this.state;
      if(isFilters[key] === value) delete isFilters[key];
      else isFilters[key] = value; 
      await this.setState({isFilters});

      this.prepareFiltersData(this.getFilteredTickets(this.state.tickets));

    }

    clearFilters = async () => {
      await this.setState({ isFilters: {} })
      this.prepareFiltersData(this.getFilteredTickets(this.state.tickets));
    }

    getFilteredTickets = (tickets=[]) => {
      let { isFilters } = this.state;
      let startDate = isFilters.start_date ? moment(isFilters.start_date).startOf("day").toDate() : null;
      let endDate = isFilters.end_date ? moment(isFilters.end_date).endOf("day").toDate() : null;
      return tickets.filter(ticket => {
          if(startDate && !moment(startDate).isBefore(ticket.created_on)) return false;
          if(endDate && !moment(endDate).isAfter(ticket.created_on)) return false;
          if(isFilters.agent_user_id && ticket.agent_user_id !== isFilters.agent_user_id) return false;
          if(isFilters.ticket_type && (ticket.ticket_type || 'NA') != isFilters.ticket_type) return false;
          if(isFilters.job_id && !(ticket.tag_type === 'JOB' && ticket.tag_id == isFilters.job_id)) return false;
        return true;
      })
    }

    render() {
      var userRole = store.getState().user.UserRole;
      let isAdmin = !!userRole.filter(role => role.role_id === 1).length;
      let { tabCounts, activeTab, tickets, activeTicket, ticketSearchText, isRefreshing, isFilters, ticketAgents, ticketTypeFilterData, ticketFilterJobs } = this.state;

      if(Object.keys(isFilters).length) tickets = [...this.getFilteredTickets(tickets)]

      if(ticketSearchText) {
        ticketSearchText = ticketSearchText.toLowerCase().replace("pmw", "");
        tickets = tickets.filter(obj =>  (obj.User.first_name || "").toLowerCase().includes(ticketSearchText) ||  ("" + obj.User.id).includes(ticketSearchText));
      }

      let selectedTicketIndex = (tickets || []).findIndex(o => o.ticket_id === activeTicket.ticket_id)

        return(
          <>
          <Drawer variant="permanent" anchor={"right"} ModalProps={{style: {zIndex: 1045}}} PaperProps={{ style: {width: "65%", overflow: "hidden", boxShadow: "0 1rem 10rem #000"} }} open={true} onClose={() => this.onClose()}>
            <div  className="clickable m-1 p-1"  style={{position: "absolute", left: 0, top: 0, zIndex: 9 }} onClick={() => this.onClose()}>
              <Icon fontSize="" >close</Icon>
            </div>
            <Icon className="clickable m-1" fontSize="small" style={{position: "absolute", right: 0, top: 0, transition: '10s, transform 2s', ...(isRefreshing ? {transform: 'rotate(900deg)'} : {})}} onClick={() => this.refreshTickets()}>sync</Icon>
              <Row className="align-items-center mx-3 my-2">
                  {
                    TICKET_TABS.map((tab, i) => {
                      let isActiveTab = tab.status === activeTab;
                      return <Col key={i} className="text-center py-2" style={{cursor: "pointer", borderBottom: isActiveTab ? "3px solid #dc3545" : "none", color: isActiveTab ? "#dc3545" : "#000" }} onClick={() => this.selectTab(tab)}>
                          <span className="d-block large font-weight-bold">{ (tabCounts[tab.countKey] || 0).toString().padStart(2, '0') }</span>
                          <span className="small">{tab.label}</span>
                        </Col>
                    })
                  }
                  <Col xs={"auto"} >
                    <Button variant="outlined" size="small" style={{borderRadius: 18, borderColor: "#dc3545"}} className="text-danger text-capitalize" onClick={this.openCreateTicket}>
                        <Icon fontSize="small" className="mr-1 align-middle">add</Icon> Create
                    </Button>
                  </Col>
                  <Col xs={"auto"} className="text-right">
                    {isAdmin && <span className="d-block small mb-2"><Switch size="small" color="secondary" checked={this.state.isAdminView} onChange={(e) => this.showAdminView(e.target.checked)}  />{" "} View as Admin</span>}
                  </Col>
              </Row>
              <hr className="m-0" />
              <Row className="align-items-center my-1">
                <Col xs={"auto"} className=" p-0 pl-4 ">
                  <Icon className="align-middle" style={{fontSize: 18}} onClick={() => this.clearFilters()}>{Object.keys(isFilters).length ? "filter_alt_off" : "filter_alt"}</Icon>
                </Col>
                  <Col xs={2}>
                      <DatePicker selected={isFilters.start_date || null} onChange={date => {isFilters.start_date= date; this.setState({isFilters})}} isClearable dateFormat='dd-MMM-yyyy' placeholderText="Start Date" />
                  </Col>
                  <Col xs={2}>
                      <DatePicker selected={isFilters.end_date || null} onChange={date => {isFilters.end_date= date; this.setState({isFilters})}} isClearable dateFormat='dd-MMM-yyyy' placeholderText="End Date" />
                  </Col>
                  <Col xs={2}>
                    <SelectList placeholder="Assignee" value={isFilters.agent_user_id || ""} onChange ={(e) => this.onChangeFilter("agent_user_id", e.target.value)} >
                      {ticketAgents.map((obj, i) =>  <option key={i} value={obj.user_id}>{obj.first_name} - {obj.ticketCount}</option> )}
                    </SelectList>
                  </Col>
                  <Col xs={2}>
                    <SelectList placeholder="Ticket Type" value={isFilters.ticket_type || ""} onChange ={(e) => this.onChangeFilter("ticket_type", e.target.value)} >
                    {Object.keys(ticketTypeFilterData).map((ticketTypeId, i) => 
                        <option key={i} value={ticketTypeId}>{CONSTANTS.TICKET_TYPE_TEXT[ticketTypeId] || 'NA'} - {ticketTypeFilterData[ticketTypeId]}</option>
                    )}
                    </SelectList>
                  </Col>
                  <Col xs={3}>
                    <form onSubmit={this.searchTicketByID}><InputField placeholder="Search by Ticket ID" type="number" name="search_ticket" /></form>
                  </Col>
                  <Col>
                    {["20", "30", "40"].includes(isFilters.ticket_type) &&
                    <SelectList placeholder="Project" value={isFilters.job_id || ""} onChange ={(e) => this.onChangeFilter("job_id", e.target.value)} >
                      {ticketFilterJobs.map((job, i) => 
                          <option key={i} value={job.job_id}>{job.Employer.employer_name}-{job.title} - {job.ticketCount}</option>
                      )}
                    </SelectList>
                    }
                  </Col>
              </Row>
              <hr className="m-0" />
              <Row className="h-100 " noGutters style={{overflow: "hidden"}}>
                  <Col xs={5} id="ticket-container" className="border-right px-3 h-100" style={{ overflowY: "auto"}}>
                      <Row className="py-1 border-bottom align-items-center" style={{background: "#fff", zIndex: 99, top: 0, position: "sticky"}}>
                          <Col><InputField variant="outlined" onChange={(e) => this.setState({ticketSearchText: e.target.value})} icon="search" placeholder="Search by Name/PMW-Id"  /></Col>
                      </Row>
                      
                      {
                        tickets.map(ticket => {
                          return (
                          <Row className="py-2 px-1 border-bottom align-items-center" noGutters  style={{position: "relative", margin: "0 -15px 0 -15px", cursor: "pointer", background: ticket.ticket_id === activeTicket.ticket_id ? "#fcf1f1" : "#fff"}}>
                              <Col xs={1} className="" onClick={(e) => this.setState({ isOpenUserModel: ticket.User })}>
                                  <img className="rounded-circle" style={{width: "100%" , aspectRatio: "1"}} src={ticket.User.profile_picture_url || DUMMY_PROFILE_IMG}  alt="Profile Pic.." />
                              </Col>
                              <Col onClick={() => this.selectTicket(ticket)} className="pl-2">
                                <span className="d-block" >{ticket.User.first_name} (PMW{ticket.User.id})</span>
                                <span className="d-block small">{ticket.User.UserConfigs && ticket.User.UserConfigs[0] ? ticket.User.UserConfigs[0].city + ", " + ticket.User.UserConfigs[0].state : ""}</span>
                                <div>
                                  <span className="small">{CONSTANTS.TICKET_TYPE_TEXT[ticket.ticket_type] || ticket.ticket_title || "NA"}</span>
                                  <Icon className="align-middle ml-2" style={{fontSize: 16}}>
                                    {ticket.ticket_source === 0 && "support_agent" }
                                    {ticket.ticket_source === 1 && "call" }
                                    {ticket.ticket_source === 2 && "email" }
                                    {ticket.ticket_source === 3 && "person" }
                                    {ticket.ticket_source === 4 && "feedback" }
                                  </Icon>
                                  <div className="float-right text-right">
                                    {!!ticket.Agent && <span className="border px-2 bg-light small" style={{borderRadius: 12, fontSize: 10}}>{ticket.Agent.first_name}</span>}
                                    <span className="d-block" style={{fontSize: 10}}>{moment(ticket.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm A")}</span>
                                  </div>
                                </div>
                              </Col>
                              {!!ticket.rating && <span title={ticket.rating_comment || "No Comment"} style={{fontSize: 18, position: "absolute", right: 5, top: 2}}> { {"1": "😤", "2": "😔", "3": "😐", "4": "😊", "5": "😍"}[ticket.rating]} </span>}
                              {
                                ticket.ticket_id === activeTicket.ticket_id && 
                                <div style={{position: "absolute", right: -6, top: 20}}>
                                  <Icon className="text-danger" fontSize="large">keyboard_arrow_right</Icon>
                                </div>
                              }
                          </Row>
                        )})
                      }
                  </Col>
                  <Col xs={7} className="px-3 h-100">
                      {
                        !!this.state.isCreateTicket && <TicketCreate addTicket={this.addTicketInData} onClose={this.openCreateTicket} />
                      }
                      {
                        !!activeTicket ? 
                          <TicketDetail 
                            ticketData={ activeTicket } 
                            selectTicket={this.selectTicket} 
                            markTicketResolved={this.markTicketResolved}
                            updateTicketData={this.updateTicketData}
                            addRemoveUpdateTicket={this.addRemoveUpdateTicket}
                            prevTicket={ selectedTicketIndex === 0 || selectedTicketIndex === -1  ?  false : tickets[selectedTicketIndex-1]}
                            nextTicket={tickets.length ===  selectedTicketIndex+1 ?  false : selectedTicketIndex === -1 ? tickets[selectedTicketIndex+1][0] : tickets[selectedTicketIndex+1]} 
                          />
                          :
                          <div className="text-center my-5"><span className="small"><em>No Ticket Selected, Select Ticket to see details</em></span></div>
                      }
                  </Col>
              </Row>
          </Drawer>
          {!!this.state.isOpenUserModel && <PartnerDetail {...this.state.isOpenUserModel} toggle={() => this.setState({isOpenUserModel : false})} />}

          </>
        );
     }
  }
  export default withRouter(props => <SupportTickets {...props} />);


