import React, { Component } from 'react';

// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// If you want to use the provided css
// import 'react-google-places-autocomplete/dist/index.min.css';
import store from '../../store';
import { setError } from '../../actions/action-creators';
import InputFeild from './form/input';

const google = window.google;

class GoogleAddress extends Component {
 
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current);
    this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'name']);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged(){
    try{
      const place = this.autocomplete.getPlace();
      var address_components = place.address_components.reduce((prev, current) => {
        prev[current.types[0]] = current.long_name;
        return prev;
      }, {})
      var data = {
        address : place.name+", "+place.formatted_address,
        city : address_components.administrative_area_level_3 || address_components.administrative_area_level_2,
        state : address_components.administrative_area_level_1,
        country : address_components.country,
        zip : address_components.postal_code,
        lat : place.geometry.location.lat(),
        long : place.geometry.location.lng(),
      };
      this.props.onPlaceChange(data);
    }catch(e){
      console.log(e)
      store.dispatch(setError({show: true, message: e.toString()}))
    }

  }


 
	
	render() {
		return (
            <div>
            {/* <GooglePlacesAutocomplete
              onSelect={console.log}
            /> */}
             <InputFeild inputRef={this.autocompleteInput} defaultValue={this.props.defaultValue || ""}  id="autocomplete" placeholder={this.props.placeholder || "Enter your address"} />
          </div>
		)
	}
}

export default GoogleAddress;
